<template>
  <v-container id="inventory" fluid tag="section">
    <v-snackbar v-model="updateLowInventory" :timeout="4000" top>
      <span>Van Low Inventory Updated Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="updateLowInventory = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-card>
      <v-card-title> <h3>Low Inventory Alert for Van</h3></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-form ref="lowInventory">
              <v-container class="py-2">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      type="number"
                      min="1"
                      step="1"
                      label="Low Inventory Alert"
                      class="purple-input"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      v-model="low_inventory"
                      :rules="inventoryRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text">
                    <v-btn
                      type="button"
                      color="primary"
                      class="mr-0 float-right"
                      @click="submitLowInventory"
                      :loading="loadingLowInventory"
                      >Update</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- </v-col> -->
  </v-container>
</template>

<script>
export default {
  name: "LowVanInventory",
  data() {
    return {
      low_inventory: "",
      lowInventoryID: "",
      loadingLowInventory: false,
      updateLowInventory: false,
      inventoryRule: [(v) => !!v || "Low Inventory is required"],
    };
  },
  methods: {
    getLowInventory() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "setting/edit/low_inventory")
        .then((response) => {
          if (response.status == 200) {
            this.lowInventoryID = response.data.setting._id;
            this.low_inventory = response.data.setting.value;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitLowInventory() {
      if (this.$refs.lowInventory.validate()) {
        this.loadingLowInventory = true;
        let data = {
          id: this.lowInventoryID,
          type: "low_inventory",
          value: this.low_inventory,
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "setting/update", data)
          .then((response) => {
            if (response.status == 200) {
              this.loadingLowInventory = false;
              this.updateLowInventory = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.getLowInventory();
  },
};
</script>

<style>
</style>